<template>
  <div class="wrap pr">
    <div id="gender-chart" style="height:400px;"></div>
    <div
      v-if="nodata"
      class="pa dp-flex align-item-c justify-c"
      style="top:0;left:0;bottom:0;right:0;background-color: #00000008"
    >
      暂无数据
    </div>
    <div
      class="pa"
      :style="`top:0;left:50px; color:${themeColor};font-weight: 500`"
    >
      性别年龄
    </div>
  </div>
</template>

<script>
import { setAxisMaxMin, setAxisMaxMinV3 } from "./tool";
export default {
  props: {
    selFields: {
      type: Array,
      default: () => []
    },
    legends: {
      type: Array,
      default: () => []
    },
    chartData: {
      type: Array,
      default: () => []
    },
    sum: {
      type: Object,
      default: () => {}
    },
    units: {
      type: Array,
      default: () => []
    },
    themeColor: {
      type: String,
      default: () => ""
    },
    fields: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      myChart: {},
      chartOption: {
        title: [],
        xAxis: {},
        yAxis: [],
        series: []
      },
      colors: ["#FF99C3", "#6DC8EC", "#B6BDCF", "#D4D9E3"],
      nodata: true,
      genders: [
        { label: "女性", value: "female" },
        { label: "男性", value: "male" }
      ]
    };
  },
  computed: {
    newLegends() {
      let arr = [];
      arr.push(...this.genders.map(item => item.label));
      arr.push(
        ...this.genders.map(
          item =>
            `${this.legends[1]}${item.label}${this.selFields[0].label}数据`
        )
      );
      // console.log("newLegends", arr);
      return arr;
    }
  },
  methods: {
    getUnit(key) {
      let _unit = "";
      if (key != "inline_link_click_ctr") {
        _unit = Object.values(this.fields).find(f => f.showInFaceBook == key)
          .unit;
      } else {
        _unit = "";
      }
      return _unit;
    },
    updateChart() {
      this.myChart = this.$echarts.init(
        document.getElementById("gender-chart")
      );
      this.myChart.setOption(this.chartOption, true);
      // 依据echarts自动生成的坐标轴的刻度范围设置最大最小值
      setAxisMaxMinV3(this.myChart, this.chartOption, "xAxis", 4);
      this.myChart.setOption(this.chartOption, true);
      // console.log("11-this.genderchartOption", this.chartOption);
    },
    createTitle(data, genders, newLegends, colors, sum) {
      let arr = [];
      genders.forEach((item, i) => {
        let text = "",
          subtext = "";
        let unit = this.getUnit(this.selFields[0].value);
        function addBracket(data) {
          return data ? `（${data}）` : "";
        }
        function formatVal(i, key) {
          return (
            sum[genders[i].value][key + "_formatted"] +
            unit +
            addBracket(sum[genders[i].value][key + "_pecent"])
          );
        }
        if (i == 0) {
          text = `${newLegends[0]}: ${formatVal(i, "value")}`;
          subtext = `${newLegends[2]}: ${formatVal(i, "industry_value")}`;
        } else {
          text = `${newLegends[1]}: ${formatVal(i, "value")}`;
          subtext = `${newLegends[3]}: ${formatVal(i, "industry_value")}`;
        }
        let obj = {
          text,
          left: i == 1 ? 450 : 150,
          textStyle: {
            color: colors[i],
            fontSize: 14,
            fontWeight: 500
          },
          subtext
        };
        arr.push(obj);
      });
      return arr;
    },
    createTooltip(data, arr, newLegends, colors) {
      let obj = {
        trigger: "axis",
        extraCssText: `width:360px;background-color:#fff;color:${this.themeColor};box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px, rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;border-radius: 2px;border: 1px solid #e5e6e9;`,
        axisPointer: {
          type: "shadow"
        },
        formatter: params => {
          // console.log("tooltip-params", params);
          let age = params[0].axisValueLabel;
          let curArr = data.filter(d => d.age == age && d.gender != "unknown");
          let spendKey = ["spend", "industry_spend"];
          let result = "";
          result += `<p>${age}<p><p>${arr[0].label}<p>`;
          let f = curArr.find(c => c.gender == "female");
          let m = curArr.find(c => c.gender == "male");
          let _unit = this.getUnit(arr[0].value);
          curArr.forEach((item, i) => {
            let _v = i == 0 ? f : m;
            result += `<div class="dp-flex align-item-c">
              <div class="mr-10" style="width:60%;">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  colors[i]
                }"></span>
                <span style="margin-right:20px;">${newLegends[i]}：</span>
                <span>${
                  _v[arr[0].value].value_formatted &&
                  _v[arr[0].value].value_formatted != "0"
                    ? _v[arr[0].value].value_formatted + _unit
                    : "--"
                }</span>
              </div>
              <div>${arr[1].label}：${
              _v[spendKey[0]].value_formatted &&
              _v[spendKey[0]].value_formatted != "0"
                ? _v[spendKey[0]].value_formatted + "USD"
                : "0USD"
            }</div>
            </div>
            <div class="dp-flex align-item-c">
              <div class="mr-10" style="width:60%;">
                <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                  colors[i + 2]
                }"></span>
                <span style="margin-right:20px;">${newLegends[i + 2].slice(
                  0,
                  6
                )}：</span>
                <span>${
                  _v["industry_" + arr[0].value].value_formatted &&
                  _v["industry_" + arr[0].value].value_formatted != "0"
                    ? _v["industry_" + arr[0].value].value_formatted + _unit
                    : "--"
                }</span>
              </div>
              <div class="text-a-l">${arr[1].label}：${
              _v[spendKey[1]].value_formatted &&
              _v[spendKey[1]].value_formatted != "0"
                ? _v[spendKey[1]].value_formatted + "USD"
                : "0USD"
            }</div>
            </div>
            `;
          });
          return result;
        }
      };
      return obj;
    },
    createxAxis(data, newLegends) {
      let _arr = [];
      newLegends.forEach((item, i) => {
        let position = i >= 2 ? "bottom" : "top";
        let gridIndex = i == 0 || i == 2 ? 0 : 1;
        let obj = {
          position,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          gridIndex,
          inverse: i == 0 || i == 2 ? true : false
        };
        _arr.push(obj);
      });
      return _arr;
    },
    createyAxis(data, genders) {
      let arr = [];
      genders.forEach((g, i) => {
        let obj = {
          type: "category",
          boundaryGap: true,
          data: data.filter(d => d.gender == g.value).map(item => item.age),
          gridIndex: i,
          axisTick: {
            show: false
          },
          axisLabel: {
            show: i == 1 ? false : true
          },
          alignWithLabel: true
        };
        arr.push(obj);
      });
      return arr;
    },
    createSeries(data, arr, newLegends) {
      // console.log("newLegends[0].value", this.genders[0].value);
      let femaleData = data.filter(d => d.gender == this.genders[0].value);
      let maleData = data.filter(d => d.gender == this.genders[1].value);
      // console.log("femaleData,maleData", femaleData, maleData);
      let _arr = [];
      newLegends.forEach((item, i) => {
        let yAxisIndex = i == 0 || i == 2 ? 0 : 1;
        let _data = i == 0 || i == 2 ? femaleData : maleData;
        let key = i < 2 ? arr[0].value : "industry_" + arr[0].value;
        let formatData = _data.map(d => {
          return d[key] && d[key].value ? d[key].value : 0;
        });
        let obj = {
          symbol: "none",
          name: item,
          type: "bar",
          data: formatData,
          xAxisIndex: i,
          yAxisIndex
        };
        _arr.push(obj);
      });
      // console.log("createSeriesData", _arr);
      return _arr;
    },
    createOption(data, selArr, legends, colors) {
      this.chartOption.title = this.createTitle(
        data,
        this.genders,
        this.newLegends,
        colors,
        this.sum
      );
      this.chartOption.grid = [
        {
          left: 50,
          bottom: 50,
          top: 80,
          width: "40%",
          containLabel: false
        },
        {
          left: "55%",
          bottom: 50,
          top: 80,
          right: 50,
          width: "40%",
          containLabel: false
        }
      ];
      this.chartOption.color = this.colors;
      this.chartOption.legend = {
        data: this.newLegends,
        icon: "circle",
        bottom: 0,
        itemGap: 20,
        itemWidth: 10,
        itemHeight: 10
      };
      this.chartOption.tooltip = this.createTooltip(
        data,
        selArr,
        this.newLegends,
        colors
      );
      this.chartOption.yAxis = this.createyAxis(data, this.genders);
      this.chartOption.yAxis.inverse = true;
      this.chartOption.xAxis = this.createxAxis(data, this.newLegends);
      this.chartOption.series = this.createSeries(
        data,
        selArr,
        this.newLegends
      );
      // // 设置坐标轴最大最小值和间隔
      // setAxisMaxMin("xAxis", this.chartOption, {
      //   noDataSplitNum: 4,
      //   hasDataSplitNum: 4
      // });
      // 无数据时文字提示
      this.nodata = this.chartOption.series.every(item =>
        item.data.every(d => d == "0" || !d)
      );
      // console.log("this.genderchartOption", this.chartOption);
      this.updateChart();
    }
  },
  mounted() {
    //图表初始化
    this.createOption(
      this.chartData,
      this.selFields,
      this.legends,
      this.colors
    );
  },
  watch: {
    chartData: {
      handler(val) {
        //图表初始化
        this.createOption(val, this.selFields, this.legends, this.colors);
      },
      deep: true
    }
  }
};
</script>

<style lang='scss'>
@import "@/styles/commonly";
#gender-chart {
  margin-top: 20px;
}
</style>