<template>
  <el-row :gutter="16" class="fields-wrap dp-flex justify-btw">
    <el-col
      v-for="(item, index) in cusFieldsDatas"
      :key="index"
      :class="{ 'col-active': item.selected }"
    >
      <div class="pr cursor-pointer bg-f" @click="handleChangeItemSel(index)">
        <div class="dp-flex align-item-c mb-10">
          <!-- 叠圆 -->
          <div
            class="big-circle pr"
            :style="`background-color:${colors[index]}`"
          >
            <div class="small-circle pa"></div>
          </div>
          <!-- 下拉框 -->
          <el-dropdown
            size="small"
            placement="bottom-start"
            @command="c => handleCommand(c, index)"
          >
            <span
              >{{
                commandField(item.value, {
                  arr: formatFields,
                  valKey: "value",
                  labelKey: "label"
                })
              }}<i class="el-icon-arrow-down el-icon--right"></i
            ></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(f, fi) in formatFields"
                :key="fi"
                :command="f.value"
                :disabled="f.disabled"
                >{{ f.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 本周期数据 -->
        <p class="mb-10">
          <span
            class="current-period mr-10"
            :style="`color:${colors[index]}`"
            >{{ formatVal(overviewDatas, item.value, "value_formatted") }}</span
          >
          <span class="unit">{{ getUnit(item.value) }}</span>
        </p>
        <!-- 对比上周期 -->
        <p class="dp-flex justify-btw">
          <span class="f-14 color-59 dp-flex align-item-c"
            >{{ formatVal(overviewDatas, item.value, "chain_index_formatted")
            }}<i
              v-if="compareText(overviewDatas, item.value) == '相比上周期'"
              :class="[
                'f-16',
                'color-green',
                'ml-10',
                lessThanZero(overviewDatas, item.value, 'chain_index_formatted')
                  ? 'el-icon-caret-bottom'
                  : 'el-icon-caret-top'
              ]"
            ></i></span
          ><span class="unit comare-text">{{
            compareText(overviewDatas, item.value)
          }}</span>
        </p>
        <!-- bar -->
        <div
          class="simulate-bar-f pa"
          :style="`background-color:${colors[index]}`"
        ></div>
        <div
          class="simulate-bar pa"
          :style="`background-color:${colors[index]}`"
        ></div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
// import { mapState } from "vuex";
import { getOverviewDatas } from "@/api/accountOverview";
export default {
  props: {
    fields: {
      type: Object,
      defalut: () => {}
    },
    start_date: {
      type: String,
      default: () => ""
    },
    end_date: {
      type: String,
      default: () => ""
    },
    colors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formatFields: [],
      cusFieldsDatas: [],
      // 选中的卡片数组
      selArr: [],
      // 概览-数据
      overviewDatas: {}
    };
  },
  computed: {
    // ...mapState("num", ["activeAdAccount"]),
    commandField() {
      return function(com, { arr, valKey, labelKey }) {
        let obj = arr.find(item => item[valKey] == com);
        return obj ? obj[labelKey] : "";
      };
    },
    formatVal() {
      return function(arr, val, key) {
        if (arr.each_fields && arr.each_fields[val]) {
          return arr.each_fields[val][key] ? arr.each_fields[val][key] : "--";
        } else {
          return "--";
        }
      };
    },
    lessThanZero() {
      return function(arr, val, key) {
        if (
          this.formatVal(arr, val, key) != "--" &&
          this.formatVal(arr, val, key).indexOf("-") != -1
        ) {
          return true;
        } else {
          return false;
        }
      };
    },
    compareText() {
      return function(arr, val) {
        let tip = "";
        // 本周期无数据
        if (
          !(
            arr.each_fields &&
            arr.each_fields[val] &&
            arr.each_fields[val]["value"]
          )
        ) {
          tip = "本周期无数据";
        } else if (!arr.each_fields[val]["chain_value"]) {
          // 上周期无数据
          tip = "上周期无数据";
        } else {
          // 相比上周期
          tip = "相比上周期";
        }
        // console.log("tip", tip);
        return tip;
      };
    },
    dataChangeParams() {
      return {
        start_date: this.start_date,
        end_date: this.end_date,
        fields: this.cusFieldsDatas.map(item => item.value)
      };
    },
    getUnit() {
      return function(key) {
        let obj = this.formatFields.find(f => f.value == key);
        if (key != "inline_link_click_ctr" && obj) {
          return obj.unit;
        } else {
          return "";
        }
      };
    }
  },
  methods: {
    // 概览数据
    getOverviewDatas() {
      this.$showLoading();
      let params = {
        ad_account_ids: [this.$store.getters.accountId],
        end_date: this.end_date,
        fields: this.cusFieldsDatas.map(item => item.value),
        start_date: this.start_date
      };
      getOverviewDatas(params).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.overviewDatas = res.data;
          // console.log("this.overviewDatas", this.overviewDatas);
        }
      });
    },
    createFormatFields() {
      let arr = [],
        keys = Object.keys(this.fields);
      // console.log("this.fields", this.fields);
      keys.forEach(k => {
        // let unit = "",
        //   usdArr = [
        //     "spend",
        //     "cpm",
        //     "cost_per_inline_link_click",
        //     "purchase_value",
        //     "cpa"
        //   ],
        //   timesArr = ["inline_link_click_ctr"];
        // console.log("this.fields[k]", this.fields[k]);
        // if (usdArr.some(item => item == this.fields[k])) {
        //   unit = "USD";
        // } else if (timesArr.some(item => item == this.fields[k])) {
        //   unit = "%";
        // } else if (this.fields[k] == "roas") {
        //   unit = "";
        // } else {
        //   unit = "次";
        // }
        let obj = {
          label: k,
          value: this.fields[k].showInFaceBook,
          disabled: false,
          unit: this.fields[k].unit
        };
        arr.push(obj);
      });
      // console.log("createFormatFields", arr);
      return arr;
    },
    createCusFieldsDatas() {
      let arr = [];
      let keys = Object.keys(this.fields);
      keys.forEach((k, i) => {
        if (i <= 4) {
          let obj = {
            label: k,
            selected: false,
            value: this.fields[k].showInFaceBook
          };
          arr.push(obj);
        }
      });
      // console.log("createCusFieldsDatas", arr);
      // 默认第一项与最后一项选中
      arr[0].selected = true;
      arr[arr.length - 1].selected = true;
      this.selArr = arr.filter(item => item.selected);
      // console.log("createCusFieldsDatas", arr);
      return arr;
    },
    formatFieldsFn() {
      this.formatFields = this.createFormatFields();
      this.cusFieldsDatas = this.createCusFieldsDatas();
    },
    // 是否选中
    handleChangeItemSel(index) {
      // 限定最多只能选中三个且至少保留一个
      let cur = this.cusFieldsDatas[index];
      // 1.已选中个数
      let selNum = this.selArr.length;
      // 当前操作卡片选中状态的改变
      this.cusFieldsDatas[index].selected = !this.cusFieldsDatas[index]
        .selected;
      // 2.已选中个数与3比较
      if (selNum < 3) {
        // 选中数组改变
        if (this.selArr.some(item => item.value == cur.value)) {
          // 当前操作卡片在已选中数组
          // 至少保留一个
          let cancelIndex = this.selArr.findIndex(
            item => item.value == cur.value
          );
          if (selNum == 1) {
            this.cusFieldsDatas[index].selected = true;
            this.$message({
              message: "请至少保留一项指标！",
              type: "warning"
            });
          } else {
            this.selArr.splice(cancelIndex, 1);
          }
        } else {
          // 当前操作卡片不在已选中数组
          if (this.cusFieldsDatas[index].selected) {
            this.selArr.push(this.cusFieldsDatas[index]);
          }
        }
      } else if (selNum == 3) {
        // 当前操作卡片是否在已选中数组中
        if (this.selArr.some(item => item.value == cur.value)) {
          // 在已选中数组
          // 选中数组改变
          let cancelIndex = this.selArr.findIndex(
            item => item.value == cur.value
          );
          if (!this.cusFieldsDatas[index].selected) {
            this.selArr.splice(cancelIndex, 1);
          }
        } else {
          // 不在已选中数组
          // 已选中数组中第一个取消选中
          let firstIndex = this.cusFieldsDatas.findIndex(
            item => item.value == this.selArr[0].value
          );
          this.cusFieldsDatas[firstIndex].selected = false;
          // 选中数组改变
          this.selArr.splice(0, 1);
          if (this.cusFieldsDatas[index].selected) {
            this.selArr.push(this.cusFieldsDatas[index]);
          }
        }
      }
      // console.log(
      //   "this.cusFieldsDatas, this.selArr",
      //   this.cusFieldsDatas,
      //   this.selArr
      // );
    },
    // field-change-下拉框选择
    handleCommand(c, index) {
      // console.log("handleCommand(c, index)", c, index);
      this.cusFieldsDatas[index].value = c;
      this.cusFieldsDatas[index].label = this.formatFields.find(
        item => item.value == c
      ).label;
    }
  },
  mounted() {
    this.formatFieldsFn();
  },
  watch: {
    cusFieldsDatas: {
      handler(val) {
        // 禁选项
        let selfields = val.map(item => item.value);
        this.formatFields.forEach(item => this.$set(item, "disabled", false));
        if (selfields) {
          selfields.forEach(f => {
            let index = this.formatFields.findIndex(c => c.value == f);
            if (index != -1) {
              this.$set(this.formatFields[index], "disabled", true);
            }
          });
        }
      },
      deep: true
    },
    dataChangeParams: {
      handler(val) {
        if (val) {
          // 概览数据
          this.getOverviewDatas();
        }
      },
      deep: true
    },
    selArr: {
      handler(val) {
        // console.log("watch-selArr", val);
        // 加上对应index的颜色
        val.forEach(item => {
          let i = this.cusFieldsDatas.findIndex(c => c.value == item.value);
          item.color = this.colors[i];
        });
        this.$emit("post-selArr", val);
      },
      deep: true
    }
  }
};
</script>

<style lang='scss'>
@import "@/styles/commonly";
.fields-wrap {
  .el-col {
    & > div {
      box-shadow: rgba(0, 0, 0, 0.05) 0 9px 28px 8px,
        rgba(0, 0, 0, 0.08) 0 6px 16px 0, rgba(0, 0, 0, 0.12) 0 3px 6px -4px;
      border-radius: 2px;
      opacity: 0.95;
      border: 1px solid rgb(229, 230, 233);
      padding: 16px 0 0 24px;
      height: 136px;
      transition: ease-in;
    }
    // 选中状态
    &.col-active {
      .simulate-bar {
        width: 100%;
      }
      .big-circle {
        opacity: 1;
      }
    }
  }
  .simulate-bar-f,
  .simulate-bar {
    left: 0;
    bottom: -1px;
    height: 8px;
    border-radius: 0 0 2px 2px;
  }
  .simulate-bar-f {
    width: 100%;
    opacity: 0.15;
  }
  .simulate-bar {
    width: 0;
    transition: width 1s ease-in 0s;
  }
  .big-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    opacity: 0.15;
    margin-right: 7px;
    top: -2px;
  }
  .small-circle {
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    @extend .bg-f;
    opacity: 0.8;
  }
  .current-period {
    font-size: 24px;
  }
  .unit {
    color: rgb(157, 160, 163);
    font-size: 14px;
  }
  .color-59 {
    color: #595959;
  }
  .comare-text {
    margin-right: 32px;
  }
}
</style>