var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{staticClass:"fields-wrap dp-flex justify-btw",attrs:{"gutter":16}},_vm._l((_vm.cusFieldsDatas),function(item,index){return _c('el-col',{key:index,class:{ 'col-active': item.selected }},[_c('div',{staticClass:"pr cursor-pointer bg-f",on:{"click":function($event){return _vm.handleChangeItemSel(index)}}},[_c('div',{staticClass:"dp-flex align-item-c mb-10"},[_c('div',{staticClass:"big-circle pr",style:(`background-color:${_vm.colors[index]}`)},[_c('div',{staticClass:"small-circle pa"})]),_c('el-dropdown',{attrs:{"size":"small","placement":"bottom-start"},on:{"command":c => _vm.handleCommand(c, index)}},[_c('span',[_vm._v(_vm._s(_vm.commandField(item.value, { arr: _vm.formatFields, valKey: "value", labelKey: "label" }))),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.formatFields),function(f,fi){return _c('el-dropdown-item',{key:fi,attrs:{"command":f.value,"disabled":f.disabled}},[_vm._v(_vm._s(f.label))])}),1)],1)],1),_c('p',{staticClass:"mb-10"},[_c('span',{staticClass:"current-period mr-10",style:(`color:${_vm.colors[index]}`)},[_vm._v(_vm._s(_vm.formatVal(_vm.overviewDatas, item.value, "value_formatted")))]),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(_vm.getUnit(item.value)))])]),_c('p',{staticClass:"dp-flex justify-btw"},[_c('span',{staticClass:"f-14 color-59 dp-flex align-item-c"},[_vm._v(_vm._s(_vm.formatVal(_vm.overviewDatas, item.value, "chain_index_formatted"))),(_vm.compareText(_vm.overviewDatas, item.value) == '相比上周期')?_c('i',{class:[
              'f-16',
              'color-green',
              'ml-10',
              _vm.lessThanZero(_vm.overviewDatas, item.value, 'chain_index_formatted')
                ? 'el-icon-caret-bottom'
                : 'el-icon-caret-top'
            ]}):_vm._e()]),_c('span',{staticClass:"unit comare-text"},[_vm._v(_vm._s(_vm.compareText(_vm.overviewDatas, item.value)))])]),_c('div',{staticClass:"simulate-bar-f pa",style:(`background-color:${_vm.colors[index]}`)}),_c('div',{staticClass:"simulate-bar pa",style:(`background-color:${_vm.colors[index]}`)})])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }